import { castDraft } from "immer";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useImmer } from "use-immer";
import { Dialog } from "./internal";
interface Props {}
function Commands(props: Props) {
  const {} = props;
  const [state, mutate] = useImmer({
    /**
     * Whether the command palette should be open or not. The command palette is
     * opened by cmd+k, and closed by escape or clicking outside of the dialog.
     */
    open: false,
    /**
     * If the dialog is visible (even during the closing transition), this
     * object contains both the container element (a div appended to the end of
     * the body) and the React Portal (that is returned by this component).
     */
    dialog: null as null | {
      containerElement: HTMLDivElement;
      reactPortal: React.ReactPortal;
    }
  });
  function open() {
    mutate(draft => {
      draft.open = true;
      if (!draft.dialog) {
        const containerElement = document.createElement("div");
        document.body.appendChild(containerElement);
        const reactPortal = ReactDOM.createPortal(<div className={classes.root} onClick={ev => {
          if (ev.target === ev.currentTarget) {
            close();
          }
        }}>
            <Dialog open onClose={close} />
          </div>, containerElement);
        draft.dialog = castDraft({
          containerElement,
          reactPortal
        });
      } else {
        const reactPortal = ReactDOM.createPortal(<div className={classes.root} onClick={ev => {
          if (ev.target === ev.currentTarget) {
            close();
          }
        }}>
            <Dialog open onClose={close} />
          </div>, draft.dialog.containerElement as any as HTMLDivElement);
        draft.dialog.reactPortal = reactPortal;
      }
    });
  }
  function close() {
    mutate(draft => {
      draft.open = false;
      if (draft.dialog) {
        const reactPortal = ReactDOM.createPortal(<div className={classes.root}>
            <Dialog onDispose={() => {
            mutate(draft => {
              if (!draft.open && draft.dialog) {
                document.body.removeChild(draft.dialog.containerElement as any as HTMLDivElement);
                draft.dialog = null;
              }
            });
          }} />
          </div>, draft.dialog.containerElement as any as HTMLDivElement);
        draft.dialog.reactPortal = reactPortal;
      }
    });
  }
  useHotkeys("meta+k", ev => {
    ev.preventDefault();
    if (!state.open) {
      open();
    } else {
      close();
    }
  }, {
    enableOnFormTags: ["INPUT"]
  });
  useHotkeys("escape", () => {
    close();
  }, {
    enableOnFormTags: ["INPUT"]
  });

  /*
   * Crude body scroll lock when the dialog is open.
   */
  React.useEffect(() => {
    if (state.open) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "";
      };
    }
  }, [state.open]);
  return state.dialog?.reactPortal ?? null;
}
export default Commands;
const classes = {
  root: "rfei6z8"
};

require("./Commands.linaria.module.css!=!../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Commands.tsx");