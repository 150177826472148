import { cx } from "@linaria/core";
import * as React from "react";
import { Node } from "../types";
import Section from "./Section";
import * as Icons from "react-feather";
import { useContext } from "timvir/context";
interface Props extends React.ComponentPropsWithoutRef<"nav"> {
  toc: readonly Node[];
  search?: {
    open: () => void;
    label?: React.ReactNode;
    Component: React.ComponentType<{
      open: boolean;
      onClose: (ev: React.SyntheticEvent<HTMLElement>) => void;
    }>;
  };
}
function Sidebar(props: Props) {
  const {
    location
  } = useContext();
  const {
    toc,
    search,
    className,
    ...rest
  } = props;
  const node = function find(nodes: readonly Node[]): undefined | Node {
    for (const node of nodes) {
      if (node.path === location.asPath) {
        return node;
      }
      if (node.children) {
        const n = find(node.children);
        if (n) {
          return n;
        }
      }
    }
  }(toc);
  return <nav className={cx(className, classes.root)} {...rest}>
      <header className={"h17eoe18"}>
        <div className={"d1mgn6rc"}>
          <div className={"d7bx8iu"}>
            Timvir
          </div>
          <div className={"d119ou0k"} />
          <div>Docs</div>
        </div>

        {search && <div className={cx("d1f74255")}>
            <Search {...search} />
          </div>}
      </header>

      <label htmlFor="menu" className={"l5l3c45"}>
        {node?.icon ? React.cloneElement(node.icon, {
        className: "c15ju3pv"
      }) : null}
        <span>{node?.label ?? "Menu"}</span>

        <Icons.Menu size={`1rem`} className={"s2bwnbz"} />
      </label>

      <input type="checkbox" id="menu" className={"iu06l76"} onChange={ev => {
      document.body.classList.toggle(classes.scrollLock, ev.currentTarget.checked);
    }} />
      <div className={classes.content}>
        <div className={classes.sections}>
          <div className={classes.nav} onClick={() => {
          document.body.classList.remove(classes.scrollLock);
        }}>
            {toc.map((c, i) => <Section key={i} depth={0} {...c} />)}
          </div>
        </div>
      </div>
    </nav>;
}
export default Sidebar;
const classes = {
  scrollLock: "s56darb",
  root: "rwbgl5x",
  content: "c15aig6x",
  sections: "svou0q5",
  nav: "n1ibjf0n"
};
function Search(props: NonNullable<Props["search"]>) {
  const {
    open,
    label
  } = props;
  return <div className={"da95449"}>
      <div role="button" className={"d1au8wkb"} onClick={open}>
        <svg x="0px" y="0px" width="12px" height="12px" viewBox="0 0 12 12">
          <path d="M11.707 10.293l-2.54-2.54a5.015 5.015 0 10-1.414 1.414l2.54 2.54a1 1 0 001.414-1.414zM2 5a3 3 0 113 3 3 3 0 01-3-3z" fill="currentColor" />
        </svg>
        {label || "Search docs"}
      </div>
    </div>;
}

require("./Sidebar.linaria.module.css!=!../../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Sidebar.tsx");