import * as React from "react";
import { useContext } from "timvir/context";
import { Node } from "../types";
import * as Icons from "react-feather";
interface Props extends Node {
  depth: number;
  active: boolean;
  setActive: (f: (active: boolean) => boolean) => void;
}
function SidebarItem(props: Props) {
  const {
    depth,
    label,
    path,
    children = [],
    active,
    setActive
  } = props;
  const {
    location,
    Link
  } = useContext();
  const icon = children.length === 0 ? null : <Icons.ChevronRight className={classes.icon} style={{
    transform: active ? "rotate(90deg)" : "rotate(0deg)"
  }} size={16} />;
  return <div className={classes.root} data-active={location.asPath === path}>
      {path ? <Link href={path} style={{
      marginLeft: depth * 16
    }}>
          {props.icon ? React.cloneElement(props.icon, {
        className: classes.icn
      }) : null}
          <span className={classes.label}>{label}</span>
          {icon}
        </Link> : <a style={{
      marginLeft: depth * 16
    }} href="#" onClick={() => setActive(x => !x)}>
          {props.icon ? React.cloneElement(props.icon, {
        className: classes.icn
      }) : null}
          <span className={classes.label}>{label}</span>
          {icon}
        </a>}
    </div>;
}
export default SidebarItem;
const classes = {
  root: "r28qe6v",
  icn: "i1ia823q",
  label: "l13ixk6c",
  icon: "i7wcd3g"
};

require("./SidebarItem.linaria.module.css!=!../../../../../../node_modules/.pnpm/@linaria+webpack5-loader@5.0.4_webpack@5.93.0/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SidebarItem.tsx");